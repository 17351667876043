<template>
    <div class="account">
        <div class="userTitle">
            <span>{{ $t('quan-xian-guan-li') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('guan-li-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/accountInfo' }">{{ $t('zhang-hu-yu-an-quan') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('quan-xian-guan-li') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="main">
            <div class="alert">
                {{
                    $t(
                        'zhu-zhang-hao-ke-yi-sheng-cheng-duo-ge-zi-id-zhang-hao-fen-pei-gei-duo-ge-yun-ying-ren-yuan-bu-tong-de-zi-zhang-hao-id-ta-men-ke-yi-shi-yong-zi-zhang-hu-id-jia-mi-ma-de-xing-shi-jin-hang-deng-lu-suo-you-zi-id-zhang-hao-he-zhu-zhang-hao-mi-ma-yi-yang-zai-ge-zi-zhang-hao-de-deng-lu-qi-jian-hui-ji-lu-qi-cao-zuo-ri-zhi'
                    )
                }}
            </div>
        </div>

        <div class="main-info" v-loading="loading">
            <div class="empty-box" v-if="empty">
                <empty-page>
                    <template slot="text">
                        {{ $t('wei-she-zhi-zi-zhang-hao') }}
                    </template>
                </empty-page>
            </div>

            <el-form label-width="2.16rem" size="small">
                <el-form-item
                    :label="`${$t('zi-zhang-hao')}ID-${getNum(index + 1)}`"
                    v-for="(item, index) in list"
                    :key="item.id"
                >
                    <div class="account-info">
                        <span>{{ item.username }}</span>

                        <el-button
                            type="warning"
                            size="mini"
                            plain
                            @click="$router.push('/account/operating?userId=' + item.id)"
                            >{{ $t('cha-kan-ri-zhi-ji-lu') }}</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="warning" @click="add">{{ $t('xin-zeng-zi-zhang-hao') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import EmptyPage from '../../components/EmptyPage.vue';
export default {
    components: { EmptyPage },
    name: 'manage',
    data() {
        return {
            list: [],
            loading: false,
            empty: false
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        this.getList();
    },
    methods: {
        add() {
            this.$http.get('/user/subUser').then(res => {
                this.$message.success(this.$t('xin-zeng-zi-zhang-hao-cheng-gong'));
                this.getList();
            });
        },
        getList() {
            this.loading = true;
            this.empty = false;
            this.$http
                .post('/user/getSubUser')
                .then(res => {
                    this.list = res;
                    this.loading = false;
                    this.empty = res.length === 0;
                })
                .catch(e => {
                    this.$message.warning(e.error);
                    this.loading = false;
                    this.empty = true;
                });
        },
        getNum(index) {
            if (index < 10) {
                return '0' + index;
            } else {
                return index;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0;
    border-bottom: 1px solid #bcc1cc;
    margin: 0 20px;
}

.main {
    padding: 20px;
}

.alert {
    padding: 34px 20px;
    background: rgba(255, 165, 38, 0.06);
    font-size: 12px;
    color: #ffa526;
    line-height: 22px;
    text-align: center;
}

.empty-box {
    height: 300px;
    position: relative;
}

.account-info {
    width: 300px;
    height: 36px;
    background: #f5f7fa;
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-button {
        background-color: transparent;
        border-width: 0;
        &:hover {
            // background-color: #ffa526;
            color: #ffa52688;
        }
    }
}

.main-info {
    min-height: 500px;
}
</style>

<style lang="scss"></style>
